import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const aboutContent = [
  {
    // title: 'About',
    content: `
      DataN1 creates and presents a diverse portfolio of innovative applications designed to empower users. Our mission is to develop and showcase cutting-edge apps that simplify complex tasks, enhance productivity, and drive meaningful outcomes.

      Operating similarly to a venture capital or investment management firm, DataN1 develops high-quality apps and curates them into a comprehensive portfolio. Each app is crafted with a focus on innovation, usability, and security, ensuring it delivers tangible benefits and a seamless user experience.
    `,
  },
  {
    title: 'Apps',
    content: `
      Here are some of the apps being developed:
    `,
    apps: [
      {
        name: 'FitGluco',
        description: 'FitGluco is an innovative app designed to help individuals with Type 2 diabetes achieve their fitness goals while effectively managing their blood sugar levels. The platform combines personalized fitness plans, dietary guidance, and progress tracking, all tailored to the unique needs of each user.',
      },
      {
        name: 'GenAIBro',
        description: 'GenAIBro is designed to keep users updated on the latest AI innovations while providing a personalized learning experience. Leveraging the power of generative AI, GenAIBro acts as a supportive study buddy, helping users assess their AI maturity and recommending tailored learning tracks to enhance their knowledge and skills.',
      },
      {
        name: 'CarWiz',
        description: 'CarWiz redefines the car selection process with AI precision. By combining advanced AI technology with deep automotive expertise, CarWiz offers a personalized car selection experience. The platform leverages sophisticated algorithms to provide tailored vehicle recommendations, ensuring a seamless fit with the user’s lifestyle and preferences.',
      },
    ],
  },
  {
    title: 'Commitment',
    content: `
      Each app is developed with the end-user in mind, ensuring it meets the highest standards of quality and functionality. As always, please send any feedback to alfredprah@gmail.com.
    `,
  },
];

function About() {
  return (
    <Container fluid className="p-4">
      <h1 className="my-4 text-center">About DataN1</h1>
      <Row className="justify-content-center">
        <Col sm={12} md={10} lg={8} className="mb-4">
          <Card>
            <Card.Body>
              {aboutContent.map((section, index) => (
                <div key={index} className="mb-4">
                  <Card.Title>{section.title}</Card.Title>
                  <Card.Text>
                    {section.content.split('\n').map((line, idx) => (
                      <span key={idx}>{line}<br/></span>
                    ))}
                    {section.apps && (
                      <ul>
                        {section.apps.map((app, idx) => (
                          <li key={idx}>
                            <strong>{app.name}:</strong> {app.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Card.Text>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
