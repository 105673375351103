import React, { useState } from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';

const portfolios = [
  { 
    id: 1, 
    name: 'FitGluco', 
    status: '[Not Started]',
    description: 'FitGluco helps individuals with Type 2 diabetes achieve their fitness goals while managing blood sugar levels through personalized plans and progress tracking. It provides tools and resources that empower users to live healthier lives through improved glycemic control and lifestyle choices.', 
    url: 'https://www.fitgluco.com'
  },
  { 
    id: 2, 
    name: 'GenAIBro', 
    status: '[In Progress]',
    description: 'GenAIBro keeps users updated on the latest AI innovations and offers personalized learning tracks based on their AI maturity assessment. It\'s designed to help enthusiasts and professionals alike stay ahead in the rapidly evolving field of artificial intelligence.', 
    url: 'https://www.genaibro.com'
  },
  { 
    id: 3, 
    name: 'CarWiz', 
    status: '[In Progress]',
    description: 'CarWiz redefines the car selection process with AI precision. By combining advanced AI technology with deep automotive expertise, CarWiz offers a personalized experience that aligns perfectly with your lifestyle and preferences. Our platform serves as an intelligent guide throughout your car buying journey, leveraging sophisticated algorithms to provide tailored vehicle recommendations. Whether you\'re seeking a car for a weekend getaway or a long-term commitment, CarWiz delivers a seamless, informative, and enjoyable car selection experience.', 
    url: 'https://carwiz.ai'
  },
  // Add more portfolio items as needed
];

function Home() {
  const [expandedId, setExpandedId] = useState(null);

  const toggleDescription = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <Container>
      <h1 className="my-4 text-center">Portfolio</h1>
      <Row>
        {portfolios.map(portfolio => (
          <Col key={portfolio.id} sm={12} md={6} lg={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>
                  {portfolio.name} <span style={{ fontWeight: 'normal', fontSize: '0.8rem', color: '#6c757d' }}>{portfolio.status}</span>
                </Card.Title>
                <Card.Text>
                  {expandedId === portfolio.id ? portfolio.description : (
                    <span>
                      {portfolio.description.substring(0, 100)}...
                      <Button variant="link" onClick={() => toggleDescription(portfolio.id)} style={{ padding: 0, margin: 0, marginLeft: '5px', verticalAlign: 'baseline' }}>
                        See More
                      </Button>
                    </span>
                  )}
                </Card.Text>
                {expandedId === portfolio.id && (
                  <Button variant="link" onClick={() => toggleDescription(portfolio.id)} style={{ padding: 0, margin: 0, display: 'block', marginBottom: '10px' }}>
                    See Less
                  </Button>
                )}
                <div style={{ textAlign: 'center', marginTop: expandedId === portfolio.id ? '10px' : '0' }}>
                  <a href={portfolio.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                    Visit {portfolio.name}
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Home;
