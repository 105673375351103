import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const portfolios = [
  { 
    id: 1, 
    name: 'FitGluco', 
    description: 'FitGluco helps individuals with Type 2 diabetes achieve their fitness goals while managing blood sugar levels through personalized plans and progress tracking.',
    url: 'https://www.fitgluco.com'
  },
  { 
    id: 2, 
    name: 'GenAIBro', 
    description: 'GenAIBro keeps users updated on the latest AI innovations and offers personalized learning tracks based on their AI maturity assessment.',
    url: 'https://www.genaibro.com'
  },
  // Add more portfolio items as needed
];

function Portfolio() {
  return (
    <Container>
      <h1 className="my-4 text-center">Portfolio</h1>
      <Row className="justify-content-center">
        {portfolios.map((portfolio, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{portfolio.name}</Card.Title>
                <Card.Text>{portfolio.description}</Card.Text>
                <Button variant="primary" href={portfolio.url} target="_blank" rel="noopener noreferrer">
                  Visit {portfolio.name}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Portfolio;
