import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const teamMembers = [
  {
    name: 'Alfred Prah',
    role: 'Founder & Chief Innovator',
    bio: `
      Through DataN1, I have found a renewed sense of life and meaning through innovation and problem-solving. My journey in the tech world has been fueled by a passion for leveraging advanced Artificial Intelligence, Machine Learning and Data Science to create impactful solutions.

      As the Founder & Chief Innovator at DataN1, I not only lead (read as: implement) development projects but also strategize the overarching vision of integrating solutions into the broader tech ecosystem. Each app and platform I develop prioritizes innovation, usability, and security, ensuring that it not only meets current needs but also sets new industry standards.

      Looking ahead, I am excited about expanding DataN1's impact through strategic partnerships and pioneering new technologies that promise to redefine how we interact with digital environments. DataN1 stands at the forefront of this journey, committed to delivering solutions that create lasting value and enhance daily life.
    `,
  },
];

function Team() {
  return (
    <Container fluid className="p-4">
      <h1 className="my-4 text-center">Team</h1>
      <Row className="justify-content-center">
        {teamMembers.map((member, index) => (
          <Col key={index} sm={12} md={10} lg={8} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{member.name}</Card.Title>
                <Card.Text>
                  <strong>{member.role}</strong>
                  <br />
                  {member.bio.split('\n').map((line, idx) => (
                    <span key={idx}>{line}<br/></span>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Team;
